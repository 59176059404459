@import "../style/color";

.TouchInput {
    padding: 2em;

    border: 1px solid black;
    margin: 1em;

    color: black;

    font-size: 18px;
    text-transform: uppercase;

    min-width: 250px;
}

.Wrapper {
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.2);
}
