@import "../style/color";
@import "../style/breakpoints";
@import "../style/utils";

$padding: 0.25rem;

.Header {
  z-index: 10;

  width: calc(100% - 2rem);
  margin: 1rem;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @include min-width($break-md) {
    position: static;
    flex-direction: row;
  }

  &[data-state="open"] {
    // Menu full height is state is "open".
    position: fixed;
    top: 0;
    left: 0;

    // Auto height when on big screens.
    @include min-width($break-md) {
      height: auto;
    }
  }

  &[data-state="closed"] {
    // Hide content when closed
    .ContentWrapper {
      display: none;
    }

    // Hide content when closed
    @include min-width($break-md) {
      .ContentWrapper {
        display: flex;
      }
    }
  }
}

.ContentWrapper {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 1rem;

  @include min-width($break-md) {
    flex-direction: row;
  }
}

.Description {
  background-color: $background;
  max-width: 65ch; // Tailwind Prose
  padding: $padding;

  @include min-width($break-md) {
    max-width: 50%;
  }
}

.HeaderButton {
  margin-bottom: 1em;

  @include min-width($break-md) {
    display: none;
  }
}

.DownloadButton {
  background-color: $background;
  padding: $padding;
}
