@import "color";
@import "breakpoints";
@import "utils";

@font-face {
  font-family: "Trille";
  src: local("TrilleGX"),
    url("../../public/fonts/TrilleGX.woff2") format("woff2");
  font-optical-sizing: auto;
  text-rendering: geometricPrecision;
}

body {
  font-size: 14px;
  line-height: 1.25;
  font-weight: 400;

  font-family: Arial, Helvetica, sans-serif;
}

p {
  margin-bottom: 1em;

  &:last-child,
  &:only-of-type {
    margin-bottom: 0;
  }
}

button {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  margin: 0;

  font-size: 1em;
  font-weight: normal;

  height: fit-content;
  width: fit-content;

  color: inherit;
  background-color: $background;
  border: none;

  cursor: pointer;
}
