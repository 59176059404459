@import "./style/reset";
@import "./style/color";
@import "./style/type";
@import "./style/breakpoints";
@import "./style/utils";

/*
* Base
*/

* {
    box-sizing: border-box;
}

body {
    background-color: $background;
    color: black;
}

a {
    color: inherit;

    &:visited {
        color: inherit;
    }
}
