/*
* Margin classes
*/

.m {
    &b {
        &-sm {
            margin-bottom: var(--spacing-sm);
        }
        &-base {
            margin-bottom: var(--spacing-base);
        }
        &-md {
            margin-bottom: var(--spacing-md);
        }
        &-lg {
            margin-bottom: var(--spacing-lg);
        }
    }
}

/*
  * Mobile
  */

.hide {
    &-sm {
        @media screen and (max-width: $break-sm) {
            display: none;
        }
    }

    &-md {
        @media screen and (max-width: $break-md) {
            display: none;
        }
    }

    &-lg {
        @media screen and (max-width: $break-lg) {
            display: none;
        }
    }

    &-xl {
        @media screen and (max-width: $break-xl) {
            display: none;
        }
    }
}

.show {
    &-sm {
        display: none;
        @media screen and (max-width: $break-sm) {
            display: initial;
        }
    }

    &-md {
        display: none;
        @media screen and (max-width: $break-md) {
            display: initial;
        }
    }

    &-lg {
        display: none;
        @media screen and (max-width: $break-lg) {
            display: initial;
        }
    }

    &-xl {
        display: none;
        @media screen and (min-width: $break-xl) {
            display: initial;
        }
    }
}

.select {
    &-none {
        user-select: none;
    }
}

/*
* Media Queries
*/

@mixin min-width($breakpoint) {
    @media screen and (min-width: $breakpoint) {
        @content;
    }
}

@mixin max-width($breakpoint) {
    @media screen and (max-width: $breakpoint) {
        @content;
    }
}
