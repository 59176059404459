@import "../style/breakpoints";
@import "../style/utils";

.Trille {
  font-family: "Trille";
  font-variation-settings: "wdth" 35; // Default

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*
    * Max type size:
    * Will span between 0.5 em and 1 em
    */
  font-size: 75px; // Mobile
  line-height: 1;
  text-align: center;

  will-change: font-size, font-varation-settings;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  overflow: hidden;

  z-index: 1;

  &:focus-visible {
    outline: none;
  }

  user-select: none;
  cursor: default;

  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  @include min-width($break-sm) {
    // font-size: 100px; // Desktop
    // padding: 1rem 5rem;
    text-align: center;
  }
}

/*
* Editable <p> element
*/
.Text {
  font-size: 1.25; // Default

  cursor: text;
  white-space: nowrap;

  &:focus-visible {
    outline: none; // Disable focus ring when editing
  }
}
